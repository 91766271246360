<script>
import deburr from 'lodash/deburr';
import pickBy from 'lodash/pickBy';
import blockTypes from '@shared/assets/data/training_item_content_block_types';

export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  static: {
    blockTypes,
  },
  data() {
    return { query: '' };
  },
  computed: {
    blockTypes() {
      return pickBy(
        this.$options.static.blockTypes,
        (block, type) => {
          if (type === 'chatgpt') {
            return false;
          }

          return deburr(this.$t(block.text).toLowerCase())
            .includes(deburr(this.query.trim().toLowerCase()));
        },
      );
    },
    blockTypesCount() {
      return Object.keys(this.blockTypes).length;
    },
    searchPlaceholder() {
      return this.$t('search_content');
    },
    noResultsMessage() {
      return this.$t('no_content_matches', { query: this.query });
    },
  },
  watch: {
    autofocus(val) {
      val && this.$nextTick(() => this.$refs.bi.$refs.input.focus());
    },
  },
  methods: {
    onSearch(event) {
      this.query = event;
    },
  },
  i18n: {
    messages: {
      en: {
        search_content: 'Search for content',
        no_content_matches: 'No content matches the search "{query}".',
      },
      fr: {
        search_content: 'Chercher un contenu',
        no_content_matches: 'Aucun contenu ne correspond à la recherche "{query}".',
      },
    },
  },
};
</script>

<template>
  <div class="blocks">
    <form class="mb-3" @submit.prevent>
      <b-input
        ref="bi"
        type="search"
        :placeholder="searchPlaceholder"
        :value="query"
        @input="onSearch"
      />
    </form>
    <div v-if="blockTypesCount" class="columns is-multiline is-mobile is-gapless">
      <div
        v-for="(block, type) in blockTypes"
        :key="type"
        class="column p-3"
        :class="block.size || 'is-6'"
      >
        <div class="p-1">
          <button
            class="blocks_btn bradius-8 cursor-pointer"
            type="button"
            @click="$emit('add', type)"
          >
            <b-icon :pack="block.pack" :icon="block.icon" size="is-large" />
            <br>
            <span class="is-size-7">
              {{ $t(block.text) }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <p v-else class="box" v-html="noResultsMessage" />
  </div>
</template>

<style lang="scss" scoped>
.blocks {
  &_btn {
    display: block;
    height: 100px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $white;

    &:hover {
      color: $white;
      background: $primary;
    }
  }
}
</style>
